import { storyblokEditable } from '@storyblok/react/rsc'
import { type FC } from 'react'

import { type StoryblokComponentProps } from '../../model'
import { type StoryblokBrandImageCardType } from '../../model/storyblokTypes.generated'
import { StoryblokComponentsRenderer } from '../StoryblokComponentsRenderer'

export const StoryblokBrandImageCard: FC<
  StoryblokComponentProps<StoryblokBrandImageCardType>
> = ({ blok }) => {
  return (
    <a
      {...storyblokEditable(blok)}
      className="block h-[170px] rounded-xl border border-dark-primary-low p-6"
      href={blok.href?.url}
    >
      <div className="relative min-h-full min-w-full">
        <StoryblokComponentsRenderer bloks={blok.image} />
      </div>
    </a>
  )
}
